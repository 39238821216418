import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Hyderabad",
            description: (
              <>
                <Address>
                  <a href="https://maps.app.goo.gl/x4KFMg5JcgQExaCi8">
                    <AddressLine>
                      3rd Floor, Neelagiri Complex, Plot No.36-C & 43-C, Survey
                      No.60, Kondapur, Hyderabad, Telangana 500084
                    </AddressLine>
                  </a>
                  {/* <Email>hr@pragmatiq.com</Email> */}
                  <Email>info@pragmatiq.in</Email>
                  {/* <Email>internship@pragmatiqinq.com </Email> */}
                  <Phone>+91 9996664467</Phone>
                  <Phone>+91 4040173212</Phone>
                </Address>
              </>
            ),
          },
          {
            title: "New Jersey",
            description: (
              <>
                <Address>
                  <AddressLine>Skillman,</AddressLine>
                  <AddressLine>New Jersey, U.S.A</AddressLine>
                  {/* <Email>hr@pragmatiq.com</Email> */}
                  <Email>info@pragmatiq.in</Email>
                  <Phone>+91 9996664467</Phone>
                </Address>
              </>
            ),
          },
          
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
